import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../hoc/Layout"

import SectionAction from "../components/Sections/SectionAction/SectionAction"
import SectionIntro from "../components/Sections/SectionIntro/SectionIntro"

import LogoBooksellers from "../assets/images/about-us/network-booklovers/children_Booksellers_Worldwide.svg"

const bookselling = ({ data }) => {
  return (
    <>
      <Layout headerPages pages>
        <SectionIntro page="bookselling" />

        <div className="bookselling">
          <section className="container bookselling-ourAim">
            <h2>Our aim</h2>
            <div className="row info-box">
              <div className="col-6 col-md-12">
                <p>Bookselling opens up so many possibilities, with the freedom to be imaginative and discover unique new sales opportunities. We believe in encouraging a creative and innovative approach, exploring new avenues and finding new ways forward.</p>
                <p className="yellow">Our aim at Children’s Booksellers Worldwide is to make bookselling simply the greatest pleasure, providing the very best service and making a career in bookselling fun and easily accessible.</p>
                <p>We look for ways to inspire children, parents and teachers, with the aim of making every event fun, educational and meaningful. Our mission to bring top quality English and foreign language books to children is hugely rewarding and fulfilling.</p>
              </div>
              <div className="info-box-second col-6 col-md-12">
                <p>We are dedicated at Children's Booksellers Worldwide to providing support individually tailored to member's needs, whether just starting out on their bookselling journey or already experienced in the field.</p>
                <p className="blue">Our vision to bring books to children in every town, village and city can be achieved, if we come together, passionate and full of enthusiasm and positive energy.</p>
                <p>If your dream is to bring books to children, wherever you may be, I warmly invite you to join us and make that dream a reality.</p>
                <div className="info-box-image">
                  <div className="image-container">
                    <Img
                      fluid={data.zoe.childImageSharp.fluid}
                      style={{ maxHeight: data.zoe.childImageSharp.fluid.presentationHeight, maxWidth: data.zoe.childImageSharp.fluid.presentationWidth, height: "100%", width: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                      alt="ZoeVoborilova"
                    />
                  </div>
                  <ul className="no-style-type">
                    <li>
                      <strong>Zoë Voborilova</strong>
                    </li>
                    <li>
                      <strong>Managing Director</strong>
                    </li>
                    <li className="nobreak">
                      <strong>Moonwood and Children’s Booksellers Worldwide</strong>
                    </li>
                    <li>
                      <img src={LogoBooksellers} alt="LogoChildrenBooksellersWorldwide" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="container bookselling-whatWeOffer">
            <h2>What we offer</h2>
            <div className="row timetable">
              <div className="col-6 col-md-12 timetable-left">
                <h3>A ready-made bookshop</h3>
                <p>
                  The beauty of ordering at Children's Booksellers Worldwide is that both individual and combined orders of English children's publishers can be placed in one go, creating an instant, ready-made bookshop with great variety and ease. There is also the possibility
                  of combining orders of English and foreign language titles too, enabling customers to purchase children's books in whichever language they choose.
                </p>
                <div className="image-container">
                  <Img
                    fluid={data.dinos.childImageSharp.fluid}
                    objectFit="contain"
                    objectPosition="center"
                    style={{ maxHeight: data.dinos.childImageSharp.fluid.presentationHeight, maxWidth: data.dinos.childImageSharp.fluid.presentationWidth, height: "auto", width: "100%" }}
                    alt="dinos"
                  />
                </div>
                <h3 className="timetable-separate">Foreign language children's titles</h3>
                <p>
                  We are delighted to offer a growing number of children's publishers of foreign language titles too, including German, French, Spanish, Italian, Dutch, Portuguese, Romanian, Czech, Slovak, Polish, Hungarian, Greek and many more. Please feel free to get in touch
                  and request foreign language children's titles in your language.
                </p>
                <div className="image-container">
                  <Img
                    fluid={data.zebra.childImageSharp.fluid}
                    objectFit="contain"
                    objectPosition="center"
                    style={{ maxHeight: data.zebra.childImageSharp.fluid.presentationHeight, maxWidth: data.zebra.childImageSharp.fluid.presentationWidth, height: "100%", width: "100%" }}
                    alt="zebra"
                  />
                </div>
                <h3 className="timetable-separate">Direct delivery</h3>
                <p>
                  We ship directly to any address requested worldwide - any location can be reached, be it your own address or your customer's final address - we are flexible to your needs. Shipping costs with the combined order system are considerably reduced, meaning customers
                  make just one payment for the shipping of multiple publishers (shipping is charged per warehouse), on one multiple purchase invoice.
                </p>
              </div>

              <div className="col-6 col-md-12 timetable-right">
                <h3>A wide selection of English children's books</h3>
                <p>
                  At Children's Booksellers Worldwide we aim to make ordering a wide selection of children's books a quick and easy process, with over thirty publishers of English children's books to choose from and a growing number of foreign language children's publishers as
                  well. Booksellers can choose from over 65,000 titles with the support of a dedicated customer care team, happy to help in creating the bookshop they ideally envisage.
                </p>
                <div className="image-container">
                  <Img
                    fluid={data.books.childImageSharp.fluid}
                    objectFit="contain"
                    objectPosition="center"
                    style={{ maxHeight: data.books.childImageSharp.fluid.presentationHeight, maxWidth: data.books.childImageSharp.fluid.presentationWidth, height: "100%", width: "100%" }}
                    alt="simpleBooks"
                  />
                </div>
                <h3 className="timetable-separate">
                  Centrally-located warehouse
                  <br />
                  and fulfilment centre in Europe
                </h3>
                <p>Moonwood and Children's Booksellers Worldwide is situated in the heart of Europe, shipping children's books throughout Europe and worldwide in cooperation with Moonwood Distribution, based in Prague, the Czech Republic.</p>
                <p>We cooperate with</p>
                <div className="timetable-logos">
                  <figure>
                    <Img fluid={data.logosMoonwood.childImageSharp.fluid} className="moonwoodLogo" objectFit="contain" alt="logoMoonwoodDistribution" />
                  </figure>
                  <figure>
                    <Img fluid={data.logosDHL.childImageSharp.fluid} className="dhlLogo" objectFit="contain" alt="logoDHL" />
                  </figure>
                  <figure>
                    <Img fluid={data.logosGLS.childImageSharp.fluid} className="glsLogo" objectFit="contain" alt="logoGLS" />
                  </figure>
                  <figure>
                    <Img fluid={data.zasilkovna.childImageSharp.fluid} className="zasilkovnaLogo" objectFit="contain" alt="logoGLS" />
                  </figure>
                </div>
                <h3>We take care</h3>
                <p>We take care of your order from the moment it is placed, ensuring the successful packing and shipping of your books, leaving you more time to focus on your business.</p>
              </div>
            </div>
          </section>

          <div className="image-right-container">
            <section id="bookselling-process" className="bookselling-process">
              <div className="container">
                <h2>Bookselling process simplified</h2>

                <ul className="process-list no-style-type">
                  <li className="process-number">1</li>
                  <p>
                    Selecting your books
                    <br />
                    and placing an order
                  </p>
                  <li className="process-arrow"></li>
                  <li className="process-number">2</li>
                  <p>
                    Receiving the books and
                    <br />
                    presenting them to your customers
                  </p>
                  <li className="process-arrow"></li>
                  <li className="process-number">3</li>
                  <p>Taking orders online and delivering / Organising an event where the books are sold to the customer in person / Displaying the books in your retail space for customers to browse and buy in their own time</p>
                </ul>
                <ul className="process-list-text no-style-type">
                  <li>
                    Selecting your books
                    <br />
                    and placing an order
                  </li>
                  <li>
                    Receiving the books and
                    <br />
                    presenting them to your customers
                  </li>
                  <li>
                    Taking orders online and delivering /<br />
                    Organising an event where the books are
                    <br />
                    sold to the customer in person / Displaying
                    <br />
                    the books in your retail space for customers
                    <br />
                    to browse and buy in their own time
                  </li>
                </ul>
              </div>
            </section>
            <Img
              fluid={{ ...data.payment.childImageSharp.fluid, sizes: "(max-width: 475px), 475px" }}
              objectFit="cover"
              className="bookselling-process-image"
              style={{ maxHeight: data.payment.childImageSharp.fluid.presentationHeight, maxWidth: data.payment.childImageSharp.fluid.presentationWidth, height: "100%", width: "100%" }}
              alt="payment"
            />
            <section className="bookselling-sell">
              <div className="container">
                <h2>Where can you sell?</h2>

                <div className="row">
                  <div className="sell-block-1 col-4 col-md-12">
                    <div className="sell-icon sell-icon-1">
                      <span />
                    </div>
                    <h3>Online</h3>
                    <p>
                      Through your own eshop, on social
                      <br />
                      media &#40;Facebook, Instagram etc.&#41;,
                      <br />
                      through online marketplaces
                    </p>
                  </div>
                  <div className="sell-block-2 col-4 col-md-12">
                    <div className="sell-icon sell-icon-2">
                      <span />
                    </div>
                    <h3>Bookshop</h3>
                    <p>
                      Through your own bookshop or
                      <br />
                      retail space, language school or
                      <br />
                      child-oriented business
                    </p>
                  </div>
                  <div className="sell-block-3 col-4 col-md-12">
                    <div className="sell-icon sell-icon-3">
                      <span />
                    </div>
                    <h3>Personally</h3>
                    <p>
                      In person at events, such as local
                      <br />
                      family events, exhibitions,
                      <br />
                      conferences, school book fairs etc.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className="bookselling-list container">
            <div className="row">
              <div className="bookselling-list-left col-6 col-md-12">
                <h3>
                  Benefits of starting
                  <br />a bookselling business
                </h3>
                <ul className="no-style-type moonwood-list-style-type">
                  <li>A flexible and enjoyable part-time job that can fit around your other activities</li>
                  <li>A rewarding full-time job with great sales potential</li>
                  <li>Access to over 65,000 titles from a wide range of English children's publishers</li>
                  <li>Foreign language children's titles to include in your portfolio</li>
                  <li>The chance to develop new skills / a new career</li>
                  <li>Fulfilment from investing time into a product that helps children to learn</li>
                  <li>The freedom to be creative and innovative as the owner of your own independent bookshop</li>
                  <li>A business that you can grow over time and leave as a legacy for your children</li>
                </ul>
              </div>
              <div className="bookselling-list-right col-6 col-md-12">
                <h3>
                  Business potential
                  <br />
                  of bookselling
                </h3>
                <ul className="no-style-type moonwood-list-style-type">
                  <li>Generous discounts for booksellers</li>
                  <li>Combined order system enabling more publishers to be included in one order, meaning lower shipping costs</li>
                  <li>Variety increases sales - customers love a wide selection of books to choose from</li>
                  <li>Centrally-located warehouse and fulfilment centre in Europe reduces shipping costs and delivery times</li>
                  <li>Great demand for English children's books in non-native English speaking countries</li>
                </ul>
              </div>
            </div>
          </section>

          <SectionAction text="Start bookselling today<br />Get in touch to find out more" buttonText="Get in touch" buttonLink="mailto:info@moonwood-books.com?subject=Interested in selling children's books" />

          <section id="bookselling-distribution">
            <div className="container-wide">
              <h2>Book Distribution</h2>
              <div className="row">
                <div className="col-6 col-md-12">
                  <p>Whether you have been selling books for some time or are just getting started with your new bookselling business, it's a great idea to consider building a network of your own, adding a whole new and exciting element to your bookselling career options.</p>
                </div>
                <div className="col-6 col-md-12">
                  <img src={LogoBooksellers} alt="LogoChildrenBooksellersWorldwide" />
                </div>
              </div>
            </div>
          </section>

          <section className="bookselling-businessPotential container">
            <div className="row bookselling-businessPotential-box">
              <div className="col-6 col-md-12">
                <p>
                  It's hugely rewarding to be able to support others while building a local, national or international network of like-minded and enthusiastic booksellers. Choosing to share the opportunity of selling children's books with others means greater earning potential as
                  you provide a welcome service for more and more children's booksellers.
                </p>
                <p>
                  Children's Booksellers Worldwide provides support both for new members setting up their businesses, and also established businesses looking to branch out into new areas. Business training, including a step-by-step approach for booksellers starting out in their
                  career, is available on the Children's Booksellers Worldwide support group - open to all members.
                </p>
              </div>
              <div className="col-6 col-md-12">
                <div className="divider hide">
                  <div className="divider-inner-width"></div>
                </div>
                <div className="image-container">
                  <Img
                    fluid={data.bussinessTop.childImageSharp.fluid}
                    objectFit="contain"
                    style={{ maxHeight: data.bussinessTop.childImageSharp.fluid.presentationHeight, maxWidth: data.bussinessTop.childImageSharp.fluid.presentationWidth, height: "100%", width: "100%" }}
                    alt="BooksBussinessPotential"
                  />
                </div>
                <div className="divider hide show">
                  <div className="divider-inner-height"></div>
                </div>
              </div>
            </div>
            <div className="row bookselling-businessPotential-box">
              <div className="col-6 col-md-12">
                <div className="divider hide">
                  <div className="divider-inner-width"></div>
                </div>
                <div className="image-container">
                  <Img
                    fluid={data.bussinessBottom.childImageSharp.fluid}
                    objectFit="contain"
                    style={{ maxHeight: data.bussinessBottom.childImageSharp.fluid.presentationHeight, maxWidth: data.bussinessBottom.childImageSharp.fluid.presentationWidth, height: "100%", width: "100%" }}
                    alt="BooksBussinessPotential"
                  />
                </div>
                <div className="divider divider-rotate hide show">
                  <div className="divider-inner-height"></div>
                </div>
              </div>
              <div className="col-6 col-md-12">
                <h3>
                  Business potential
                  <br />
                  of book distribution
                </h3>
                <ul className="no-style-type moonwood-list-style-type">
                  <li>Bringing more books to more customers increases earning potential, through a combination of your own sales and distributor sales in addition</li>
                  <li>A network of booksellers can, in time, create a reliable second source of income</li>
                  <li>A ready-made portfolio of English and foreign language children's titles, plus a comprehensive distribution service, provided for your network by Children's Booksellers Worldwide</li>
                  <li>Full business support provided by Children's Booksellers Worldwide</li>
                </ul>
              </div>
            </div>
          </section>

          <section className="bookselling-process bookselling-process-2">
            <div className="container">
              <h2>Book distribution process simplified</h2>

              <ul className="process-list no-style-type">
                <li className="process-number">1</li>
                <p>Sharing the opportunity with others, exploring possibilities to bring children's books to more and more customers</p>
                <li className="process-arrow"></li>
                <li className="process-number">2</li>
                <p>Providing basic information, opening doors, sharing inspiration</p>
                <li className="process-arrow"></li>
                <li className="process-number">3</li>
                <p>Ensuring that the distributor has everything they need to be successful</p>
              </ul>
              <ul className="process-list-text no-style-type">
                <li>
                  Sharing the opportunity with
                  <br />
                  others, exploring possibilities to
                  <br />
                  bring children's books to more and
                  <br />
                  more customers
                </li>
                <li>
                  Providing basic information,
                  <br />
                  opening doors, sharing inspiration
                </li>
                <li>
                  Ensuring that the distributor has
                  <br />
                  everything they need to be
                  <br />
                  successful
                </li>
              </ul>
            </div>
          </section>

          <SectionAction text="Would you like to build your own<br />book distribution network?" second buttonText="Get in touch" buttonLink="mailto:info@moonwood-books.com?subject=Interested in book distribution" />
        </div>
      </Layout>
    </>
  )
}

export default bookselling

export const query = graphql`
  query {
    zoe: file(relativePath: { eq: "bookselling/zoe-voborilova.png" }) {
      childImageSharp {
        fluid(maxWidth: 196, maxHeight: 246, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    dinos: file(relativePath: { eq: "bookselling/dinos.png" }) {
      childImageSharp {
        fluid(maxWidth: 638, maxHeight: 393, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    zebra: file(relativePath: { eq: "bookselling/zebra.png" }) {
      childImageSharp {
        fluid(maxWidth: 638, maxHeight: 393, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    books: file(relativePath: { eq: "bookselling/simple-books.png" }) {
      childImageSharp {
        fluid(maxWidth: 638, maxHeight: 393, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    logosDHL: file(relativePath: { eq: "bookselling/logos/dhl_logo.png" }) {
      childImageSharp {
        fluid(maxHeight: 80, quality: 90, srcSetBreakpoints: [768, 1920]) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    logosGLS: file(relativePath: { eq: "bookselling/logos/gls_logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 80, quality: 90, srcSetBreakpoints: [768, 1920]) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    logosMoonwood: file(relativePath: { eq: "bookselling/logos/moonwood_distribution.png" }) {
      childImageSharp {
        fluid(maxHeight: 200, quality: 90, srcSetBreakpoints: [768, 1920]) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    zasilkovna: file(relativePath: { eq: "bookselling/logos/zasilkovna_logo.png" }) {
      childImageSharp {
        fluid(maxHeight: 80, quality: 90, srcSetBreakpoints: [768, 1920]) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    payment: file(relativePath: { eq: "bookselling/payment.png" }) {
      childImageSharp {
        fluid(maxWidth: 475, maxHeight: 735, pngQuality: 90, quality: 90, srcSetBreakpoints: [768, 960, 1600, 1920]) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    bussinessTop: file(relativePath: { eq: "bookselling/bussiness-potential-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 638, maxHeight: 444, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
    bussinessBottom: file(relativePath: { eq: "bookselling/bussiness-potential-bottom.png" }) {
      childImageSharp {
        fluid(maxWidth: 638, maxHeight: 444) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
          presentationHeight
          presentationWidth
        }
      }
    }
  }
`
